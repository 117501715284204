import axios from 'axios';
import React, { createContext, useState, useContext, useEffect } from 'react';

const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
    const [rates, setRates] = useState({});
    const [currency, setCurrency] = useState('AED');

    useEffect(() => {
        const loadRates = async () => {
            try {
                if (currency !== 'AED' && Object.keys(rates).length === 0) {                    
                    const response = await axios.get('https://v6.exchangerate-api.com/v6/80eb22b84e096d338814f0cd/latest/AED');
                    setRates(response.data.conversion_rates);
                }
            } catch (error) {
                console.error("Error fetching currency rates:", error);
            }
        };

        loadRates();
    }, [currency]);

    return (
        <CurrencyContext.Provider value={{ rates, currency, setCurrency }}>
            {children}
        </CurrencyContext.Provider>
    );
};

export const useCurrency = () => {
    return useContext(CurrencyContext);
};
