import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useLoading } from './contexts/LoadingContext';

import Layout from './layouts/layout/Layout';
import Dashboard from './layouts/dashboard/Dashboard';

import PrivateRoute from './utils/PrivateRoute';
import PublicRoute from './utils/PublicRoute';

import Home from './pages/auth/Home';
import Login from './pages/auth/Login';
import ProductDetails from './pages/auth/ProductDetails';
import Statistics from './pages/dashboard/Statistics';
import Products from './pages/dashboard/Products';
import Orders from './pages/dashboard/Orders';
import PlaceOrder from './pages/auth/PlaceOrder';
import ShoppingCart from './pages/auth/ShoppingCart';
import Users from './pages/dashboard/Users';
import Contact from './pages/auth/Contact';
import Shop from './pages/auth/Shop';
import Categories from './pages/dashboard/Categories';
import SentHome from './pages/auth/SentHomeOld';
import Diffuser from './pages/auth/Diffuser';
import Parfum from './pages/auth/Parfum';
import CandleReed from './pages/auth/CandleReed';
import Loading from './pages/auth/Loading';
import Promotion from './pages/auth/Promotion';
import PromoCodes from './pages/dashboard/PromoCodes'
import ScrollToTopButton from './components/ScrollToTopButton';
import { useTranslation } from 'react-i18next';
import SuccessOrder from './pages/auth/SuccessOrder';
import RefusedPayment from './pages/auth/RefusedPayment';
import ShippingPrices from './pages/dashboard/ShippingPrices';
import Services from './pages/auth/Services';

const App = () => {
  const { loading, setLoading } = useLoading();
  const { i18n } = useTranslation();
  const location = useLocation();

  const showScrollToTopButton = !location.pathname.startsWith('/dashboard');

  useEffect(() => {
    const pathsToCheck = ['/', '/premium', '/business', '/homescent', '/shop'];
    if (pathsToCheck.includes(location.pathname)) {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000); // Simulez un délai de chargement

      return () => clearTimeout(timer);
    }
  }, [location, setLoading]);

  return (
    <div dir={i18n.language === "ar" ? "rtl" :  "ltr"}>
      {loading ? <Loading /> : (
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/" element={<Layout />}>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Home />} />
              <Route path="/product/:id" element={<ProductDetails />} />
              <Route path="/place-order" element={<PlaceOrder />} />
              <Route path="/shopping-cart" element={<ShoppingCart />} />
              <Route path="/shop" element={<Shop />} />
              <Route path="/diffuser" element={<Diffuser />} />
              <Route path="/parfum" element={<Parfum />} />
              <Route path="/candle-reed" element={<CandleReed />} />
              <Route path="/promotion" element={<Promotion />} />
              <Route path="/contact" element={<Contact />} />
              {/* <Route path="/homescent" element={<SentHome />} /> */}
              <Route path="/blog" element={<Services />} />
              <Route path="/success-order" element={<SuccessOrder />} />
              <Route path="/refused-payment" element={<RefusedPayment />} />
              
            </Route>
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />}>
              <Route index element={<Statistics />} />
              <Route path="produits" element={<Products />} />
              <Route path="categories" element={<Categories />} />
              <Route path="commandes" element={<Orders />} />
              <Route path="promocodes" element={<PromoCodes />} />
              <Route path="shippingprices" element={<ShippingPrices />} />
              <Route path="utilisateurs" element={<Users />} />
            </Route>
          </Route>
        </Routes>
      )}
      {showScrollToTopButton && <ScrollToTopButton />}
    </div>
  );
};

export default App;
