import React from 'react';
import { useTranslation } from 'react-i18next';

const SelectLanguage = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language || 'en';

    const handleChange = (event) => {
        const lng = event.target.value;
        i18n.changeLanguage(lng);
    };

    return (
        <select className="mx-2 text-sm" value={currentLanguage} onChange={handleChange}>
            <option value="ar">AR</option>
            <option value="fr">FR</option>
            <option value="en">EN</option>
        </select>
    );
};

export default SelectLanguage;
