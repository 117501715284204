import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@material-tailwind/react';
import { motion, useInView, useAnimation } from 'framer-motion'

import video from '../../img/home/video.mp4';
import banner from '../../img/home/banner.png';

import premium from '../../img/home/premium.png';
import business from '../../img/home/business.png';
import home from '../../img/home/home.png';
import products from '../../img/home/produit.png'

import Marks from '../../components/home/Marks';
import { Link } from 'react-router-dom';
import { API_LINK } from '../../apiConfig';
import axios from 'axios';
import { useTranslation, Trans } from 'react-i18next';



const Home = () => {
    const { t, i18n } = useTranslation()

    const videoControls = useAnimation()
    const refvideo = useRef(null);
    const isInViewVideo = useInView(refvideo);

    const textControls = useAnimation()
    const refText = useRef(null);
    const isInViewText = useInView(refText);

    const technologieControls = useAnimation()
    const refTechnologie = useRef(null);
    const isInViewTechnologie = useInView(refTechnologie);


    useEffect(() => {
        if (isInViewVideo) {
            videoControls.start("visible")
        }

        if (isInViewText) {
            textControls.start("visible")
        }

        if (isInViewTechnologie) {
            technologieControls.start("visible")
        }
    }, [isInViewVideo, isInViewText, isInViewTechnologie])


    const [isActive, setIsActive] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
        });
    });


    return (
        <div>
            <div>
                <div
                    className={`${isActive ? 'h-[72px]' : 'h-[88px]'} top-0 bg-white fixed w-full transition-all`}
                ></div>

                <div className='sm:mt-[110px] mt-[96px] text-info'>
                    {/* sm:mt-0 mt-[96px] */}

                    <div ref={refvideo}>
                        <motion.section
                            variants={{
                                hidden: { opacity: 0, y: 75 },
                                visible: { opacity: 1, y: 0 }
                            }}
                            initial='hidden'
                            animate={videoControls}
                            transition={{ duration: 0.5, delay: 0.25 }}
                            className='relative'>
                            <div>
                                <video src={video} autoPlay loop muted playsInline className='w-full object-cover' />
                            </div>
                        </motion.section>
                    </div>

                    <div ref={refText}>
                        <motion.section
                            variants={{
                                hidden: { opacity: 0, y: 75 },
                                visible: { opacity: 1, y: 0 }
                            }}
                            initial='hidden'
                            animate={textControls}
                            transition={{ duration: 0.5, delay: 0.25 }}
                            className="mt-20 relative">
                            <div className="mx-auto grid grid-cols-1 sm:grid-cols-2 gap-6 relative">
                                <div className='sm:py-32 py-16 flex justify-center'>
                                    <Typography className='text-primary uppercase font-secondary font-light sm:text-3xl text-xl text-center tracking-wider w-2/3'>
                                        {t('standout-title-home')}
                                    </Typography>

                                </div>

                                <div className='bg-gradient-to-tr from-[#e5e7eb] to-[#f9fafb] sm:py-32 py-16 flex justify-center'>
                                    <Typography className='text-info w-2/3'>
                                        {t('standout-description-home')}
                                    </Typography>
                                </div>
                            </div>

                            <div className='flex justify-center bottom-10 absolute inset-x-0'>
                                <hr className='sm:w-2/5 w-2/3 border-2 border-[#d1d5db]'></hr>
                            </div>
                        </motion.section>
                    </div>

                    <div ref={refTechnologie}>
                        <motion.section
                            variants={{
                                hidden: { opacity: 0, y: 75 },
                                visible: { opacity: 1, y: 0 }
                            }}
                            initial='hidden'
                            animate={technologieControls}
                            transition={{ duration: 0.5, delay: 0.25 }}
                            className="mt-20">
                            <div className='container mx-auto relative'>
                                <img src={banner} className={`w-full h-auto ${i18n.language === 'ar' && '-scale-x-100'}`} />
                                <div className='absolute inset-0 sm:w-1/2 flex justify-center items-center mx-[30px]'>
                                    <div className='flex flex-col p-8'>
                                        <Typography variant='h3' className='text-primary tracking-wider font-secondary font-medium hidden sm:block'>
                                            {t('technologie-title-home')}
                                        </Typography>

                                        <p className='text-primary mt-4 hidden sm:block'>
                                            {t('technologie-description-home')}
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </motion.section>
                    </div>


                    <section className='flex justify-center items-center p-8 sm:hidden'>
                        <div className='flex flex-col'>
                            <Typography variant='h4' className='text-primary tracking-wider font-secondary font-medium sm:text-left text-center'>
                                {t('technologie-title-home')}
                            </Typography>

                            <Typography className='text-text text-justify mt-4'>
                                {t('technologie-description-home')}
                            </Typography>

                        </div>
                    </section>


                    <section className="mt-10 py-5">
                        <div className='container mx-auto relative'>
                            <Typography className='font-normal font-secondary tracking-wider text-center sm:text-3xl text-2xl text-primary'>
                                <Trans i18nKey="confidence-title-home">
                                    Embellissez votre maison avec le <br></br> 
                                    DIFFUSEUR DE PARFUM SECRET SCENTA
                                </Trans>
                            </Typography>


                            {/* <Typography variant='paragraph' className='font-normal text-center text-info mt-4'>
                                <Trans i18nKey="confidence-description-home">
                                    Partenaire de nombreux hôtels, cliniques et d’autres entreprises dans différents <br></br>
                                    secteurs d’activité.
                                </Trans>
                            </Typography> */}
                        </div>
                    </section>


                    <section className="my-10 pb-10">
                        <div className='flex justify-center'>
                            <hr className='sm:w-2/5 w-2/3 border-primary'></hr>
                        </div>
                        <div className='py-6'>
                            {/* <Marks /> */}

                            <div className="overflow-hidden">
                                <div
                                className={`flex sm:h-[50vh] ${
                                    i18n.language === 'ar' ? 'animate-scrollRtl' : 'animate-scrollLtr'
                                  }`}
                                // class="animate-scroll flex sm:h-[50vh]"
                                >
                                    <img src={products} alt='products' />
                                    <img src={products} alt='products' />
                                    <img src={products} alt='products' />
                                    <img src={products} alt='products' />

                                    <img src={products} alt='products' />
                                    <img src={products} alt='products' />
                                    <img src={products} alt='products' />
                                    <img src={products} alt='products' />
                                </div>
                            </div>





                        </div>
                        <div className='flex justify-center'>
                            <hr className='sm:w-2/5 w-2/3 border-primary'></hr>
                        </div>
                    </section>


                    {/* <section className="relative">
                        <div className='bg-secondary'>
                            <div className='container mx-auto flex flex-col items-center pt-10 pb-32'>
                                <Typography className='text-primary font-secondary tracking-wider sm:text-3xl text-xl font-normal text-center'>
                                    <Trans i18nKey="imprint-home">
                                        UNE EMPREINTE OLFACTIVE <br></br>
                                        POUR TOUS LES ESPACES
                                    </Trans>
                                </Typography>
                            </div>
                        </div>


                        <div className='container mx-auto -mt-20 mb-20'>
                            <div className="grid sm:grid-cols-3 grid-cols-1 gap-6 mt-4 text-info">
                                <Link to='homescent' className='col-span-1 relative hover:scale-105 transition duration-500'>
                                    <img src={premium} />
                                    <div className='absolute inset-0 flex justify-center items-center'>
                                        <Typography className="text-center text-white sm:text-3xl text-xl font-semibold">PREMIUM</Typography>
                                    </div>
                                </Link>
                                <Link to='homescent' className='col-span-1 relative hover:scale-105 transition duration-500'>
                                    <img src={business} />
                                    <div className='absolute inset-0 flex justify-center items-center'>
                                        <Typography className="text-center text-white sm:text-3xl text-xl font-semibold">BUSINESS</Typography>
                                    </div>
                                </Link>
                                <Link to='homescent' className='col-span-1 relative hover:scale-105 transition duration-500'>
                                    <img src={home} />
                                    <div className='absolute inset-0 flex justify-center items-center'>
                                        <Typography className="text-center text-white sm:text-3xl text-xl font-semibold">HOME</Typography>
                                    </div>
                                </Link>
                            </div>
                        </div>

                    </section> */}
                </div>


            </div>
        </div>
    )
}

export default Home