import { Typography } from '@material-tailwind/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CartContext } from '../../contexts/CartContext';
import { API_LINK } from '../../apiConfig';
import { IoMdAdd, IoMdClose, IoMdRemove } from 'react-icons/io';
import { BsCartX, BsArrowRight } from "react-icons/bs";
import { Link } from 'react-router-dom';

import { useCurrency } from "../../contexts/CurrencyContext";

const ShoppingCart = () => {
    const { t } = useTranslation();
    const { currency, rates } = useCurrency();


    const TABLE_HEAD = ["", t('product'), t('price'), t('quantity'), t('total')];


    const { cart, total, removeFromCart, increaseAmount, decreaseAmount } = useContext(CartContext);

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
        });
    });


    const [loadingRates, setLoadingRates] = useState(true);

    // Set loading to false after rates are fetched
    useEffect(() => {
        if (Object.keys(rates).length > 0) {
            setLoadingRates(false);
        }
    }, [rates]);


    const convertToCurrency = (amount) => {
        if (!loadingRates && Object.keys(rates).length > 0 && currency && rates[currency]) {
            return (amount * rates[currency]).toFixed(2);
        }
        return amount.toFixed(2);
    };


    return (
        <div dir="ltr">
            <div
                className={`${isActive ? 'h-[72px]' : 'h-[88px]'} top-0 bg-white fixed w-full transition-all`}
            ></div>

            <div className='w-full p-10 bg-primary mt-[100px] text-white flex sm:flex-row flex-col justify-evenly items-center'>
                <Typography variant="lead">
                    <span className="border-b-2 border-white uppercase">{t('my-cart')}</span>
                </Typography>
                <BsArrowRight className="text-2xl sm:block hidden" />
                <Typography variant="lead" className='sm:block hidden'>
                    <span className='uppercase'>{t('order-validation')}</span>
                </Typography>
                <BsArrowRight className="text-2xl sm:block hidden" />
                <Typography variant="lead" className='sm:block hidden'>
                    <span className='uppercase'>{t('order-completed')}</span>
                </Typography>
            </div>

            {cart.length !== 0 ? (<div className='flex md:flex-row flex-col w-full h-screen text-info'>
                <div className='md:w-[60%] p-8 w-full overflow-x-auto'>
                    <table className="w-full min-w-max table-auto text-center">
                        <thead>
                            <tr>
                                {TABLE_HEAD.map((head) => (
                                    <th
                                        key={head}
                                        className="border-y border-gray-400 p-4"
                                    >
                                        <Typography
                                            variant="paragraph"
                                            className="leading-none text-info font-medium capitalize"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {cart.map((item) => {
                                const classes = "p-4 border-b border-gray-400";
                                return (
                                    <tr key={item.id} className="text-info">
                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <div
                                                    onClick={() => removeFromCart(item.id)}
                                                    className="text-xl cursor-pointer"
                                                >
                                                    <IoMdClose className="text-gray-500 hover:text-red-500 transition" />
                                                </div>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <img src={`${API_LINK}/${item.images[0]}`} alt={item.name} className='w-16 h-16' />
                                                <Typography
                                                    variant="paragraph"
                                                    color="blue-gray"
                                                    className="capitalize"
                                                >
                                                    {item.name}
                                                </Typography>
                                            </div>
                                        </td>

                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <Typography
                                                    variant="paragraph"
                                                    color="blue-gray"
                                                    className="capitalize"
                                                >
                                                    {item.promotion ?  convertToCurrency(item.promotion).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : convertToCurrency(item.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {currency} 
                                                </Typography>
                                            </div>
                                        </td>

                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <div className="flex flex-1 max-w-[100px] items-center h-full border text-info font-medium">
                                                    <div onClick={() => decreaseAmount(item.id)} className="h-full flex-1 flex justify-center items-center cursor-pointer">
                                                        <IoMdRemove />
                                                    </div>
                                                    <div className="h-full flex justify-center items-center px-2">
                                                        {item.amount}
                                                    </div>
                                                    <div onClick={() => increaseAmount(item.id)} className="h-full flex flex-1 justify-center items-center cursor-pointer">
                                                        <IoMdAdd />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <Typography
                                                    variant="paragraph"
                                                    color="blue-gray"
                                                    className="capitalize"
                                                >
                                                    {item.promotion ? convertToCurrency(item.promotion * item.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : convertToCurrency(item.price * item.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {currency}
                                                </Typography>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                <div className='md:w-[40%] w-full p-8'>
                    <Typography
                        className='ml-1 mb-2 uppercase text-center'
                        variant='h4'
                    >
                        {t('your-cart')}
                    </Typography>
                    <div className='p-2 border border-gray-400 mb-10'>
                        <div className='flex justify-between items-center p-2 border-b-2'>
                            <Typography
                                className='ml-1 uppercase text-center'
                                variant='h6'
                            >
                                {t('product')}
                            </Typography>
                            <Typography
                                className='ml-1 uppercase text-center'
                                variant='h6'
                            >
                                {t('subtotal')}
                            </Typography>
                        </div>

                        {cart.map((item) => (
                            <div key={item.id} className='flex justify-between items-center p-2 border-b'>
                                <Typography
                                    className='ml-1 uppercase text-center font-light'
                                    variant='h6'
                                >
                                    {item.name} X {item.amount}
                                </Typography>
                                <Typography
                                    className='ml-1 uppercase text-center font-light'
                                    variant='h6'
                                >
                                    {item.promotion ? convertToCurrency(item.promotion * item.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : convertToCurrency(item.price * item.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {currency}
                                </Typography>
                            </div>
                        ))}


                        <div className='flex justify-between items-center p-2'>
                            <Typography
                                className='ml-1 text-center capitalize'
                                variant='h6'
                            >
                                {t('total')}
                            </Typography>
                            <Typography
                                className='ml-1 uppercase text-center'
                                variant='h5'
                            >
                                {convertToCurrency(total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {currency}
                            </Typography>
                        </div>
                    </div>

                    <Link to={'/place-order'}
                        className="bg-primary flex p-3 justify-center items-center text-white w-full font-medium"
                    >
                        {t('confirm-order')}
                    </Link>

                </div>

            </div>)
                :
                (
                    <div className='flex flex-col justify-center items-center h-screen text-info'>
                        <BsCartX size={180} className='text-gray-200 m-5' />
                        <Typography variant='h3' className='text-center'>
                            {t('cart-empty')}
                        </Typography>
                        <div className='m-5'>
                            <Link to={'/'}
                                className="bg-primary flex p-3 justify-center items-center text-white w-full font-medium uppercase"
                            >
                                {t('back-shop')}
                            </Link>
                        </div>
                    </div>
                )}
        </div>
    )
}

export default ShoppingCart;