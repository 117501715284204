import React, { useEffect, useRef, useState } from "react";
import { Typography } from "@material-tailwind/react";
import { motion, useInView, useAnimation } from 'framer-motion';
import { useTranslation } from 'react-i18next';


import emailjs from '@emailjs/browser'


const Contact = () => {
    const { t } = useTranslation();

    const contactControls = useAnimation()
    const refContact = useRef(null);
    const isInViewContact = useInView(refContact);

    useEffect(() => {
        if (isInViewContact) {
            contactControls.start("visible")
        }
    }, [isInViewContact])


    const form = useRef();

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [isFirstnameEmpty, setIsFirstnameEmpty] = useState(false);
    const [isLastnameEmpty, setIsLastnameEmpty] = useState(false);
    const [isPhoneEmpty, setIsPhoneEmpty] = useState(false);
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isMessageEmpty, setIsMessageEmpty] = useState(false);

    const validateInputs = () => {
        setIsFirstnameEmpty(firstname.trim() === '');
        setIsLastnameEmpty(lastname.trim() === '');
        setIsPhoneEmpty(phone.trim() === '');
        setIsEmailEmpty(email.trim() === '');
        setIsMessageEmpty(message.trim() === '');
        return (
            firstname.trim() !== '' &&
            lastname.trim() !== '' &&
            phone.trim() !== '' &&
            email.trim() !== '' &&
            message.trim() !== ''
        );
    };


    const contactHandler = async (e) => {
        e.preventDefault();
        if (validateInputs()) {
            emailjs.sendForm('service_0smo6dm', 'template_acmbc5n', form.current, 'ko3wR5T48AR8lv84C')
                .then((result) => {
                    console.log(result.text);
                    defaultValuesForInputs();
                }, (error) => {
                    console.log(error.text);
                });

        } else {
            alert('Veuillez remplir tous les champs')
        }
        e.target.reset();
    }

    const defaultValuesForInputs = () => {
        setFirstname('');
        setLastname('');
        setEmail('');
        setPhone('');
        setMessage('');
    }


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div ref={refContact} className="mt-[110px]">
            <Typography
                className='text-center p-5 pt-10 uppercase text-primary font-secondary tracking-wider sm:text-4xl text-2xl'
            >
                {t('contact-us')}
            </Typography>


            <motion.div
                variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 }
                }}
                initial='hidden'
                animate={contactControls}
                transition={{ duration: 0.5, delay: 0.25 }}
                className="w-full flex justify-center"
            >
                <form ref={form} onSubmit={contactHandler} className="container mx-auto sm:w-2/3">
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-4 text-info">
                        <div className='flex flex-col'>
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                {t('lastname')} *
                            </Typography>
                            <input type='text' placeholder={t('lastname')} id="lastname" name="lastname" onChange={(e) => setLastname(e.target.value)} className={`px-2 py-1 bg-gray-50 border placeholder:font-light ${isLastnameEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                        </div>
                        <div className='flex flex-col'>
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                {t('firstname')} *
                            </Typography>
                            <input type='text' placeholder={t('firstname')} id="firstname" name="firstname" onChange={(e) => setFirstname(e.target.value)} className={`px-2 py-1 bg-gray-50 border placeholder:font-light ${isFirstnameEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                        </div>

                        <div className='flex flex-col'>
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                {t('email')} *
                            </Typography>
                            <input type='email' placeholder={t('email')} id="email" name="email" onChange={(e) => setEmail(e.target.value)} className={`px-2 py-1 bg-gray-50 border placeholder:font-light ${isEmailEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                        </div>
                        <div className='flex flex-col'>
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                {t('phone-number')} *
                            </Typography>
                            <input type='text' placeholder={t('phone-number')} id="phone" name="phone" onChange={(e) => setPhone(e.target.value)} className={`px-2 py-1 bg-gray-50 border placeholder:font-light ${isPhoneEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                        </div>

                        <div className='flex flex-col md:col-span-2 col-span-1'>
                            <Typography
                                className='ml-1'
                                variant='paragraph'
                            >
                                {t('your-message')} *
                            </Typography>
                            <textarea type='text' placeholder={`${t('your-message')}...`} id="message" name="message" onChange={(e) => setMessage(e.target.value)} className={`px-2 py-1 bg-gray-50 h-36 border placeholder:font-light ${isMessageEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                        </div>

                    </div>

                    <div className='flex justify-center w-full'>
                        <button
                            className="bg-white p-3 mt-8 mb-10 border border-primary text-primary hover:bg-primary hover:text-white uppercase font-medium transition ease-in-out duration-300"
                        >
                            {t('send')}
                        </button>
                    </div>
                </form>
            </motion.div>
        </div>
    );
};

export default Contact;
