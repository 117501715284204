import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, CardBody, CardFooter, Chip, IconButton, Input, Option, Select, Tooltip, Typography } from '@material-tailwind/react'
import { PlusIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { BsPencil, BsTrash, BsEye, BsFileEarmarkArrowDown } from 'react-icons/bs'

import { format } from 'date-fns'
import { fr } from 'date-fns/locale'

import DatePicker from '../../components/dashboard/DatePicker'
import * as XLSX from 'xlsx';

import { AuthContext } from '../../contexts/AuthContext'
import { API_LINK } from '../../apiConfig'
import axios from 'axios'
import { DeleteOrder } from '../../widgets/modals/orders/DeleteOrder'
import { ShowOrder } from '../../widgets/modals/orders/ShowOrder'
import { UpdateStateOrder } from '../../widgets/modals/orders/UpdateStateOrder'

import { DeleteShippingPrice } from '../../widgets/modals/shippingprices/DeleteShippingPrice'
import AddShippingPrice from '../../widgets/modals/shippingprices/AddShippingPrice'



const TABLE_HEAD = ["Zone", "Pays", "Prix", ""];




const ShippingPrices = () => {
    const { authTokens } = useContext(AuthContext);
    const [shippingPrices, setShippingPrices] = useState([]);


    const [openAddShippingPrice, setOpenAddShippingPrice] = useState(false);
    const handleOpenAddShippingPrice = () => setOpenAddShippingPrice((cur) => !cur);

    const [openDeleteShippingPrice, setOpenDeleteShippingPrice] = useState(false);
    const handleOpenDeleteShippingPrice = () => setOpenDeleteShippingPrice((cur) => !cur);


    const [selectedShippingPrice, setSelectedShippingPrice] = useState();

    const [date, setDate] = useState();
    const [shippingPriceSearch, setShippingPriceSearch] = useState();

    const [page, setPage] = useState(1);
    const perPage = 10;

    const [numberOfPages, setNumberOfPages] = useState(0);


    useEffect(() => {
        axios.get(`${API_LINK}/shippingprices?page=${page}&perPage=${perPage}&country=${shippingPriceSearch}`, {
            headers: {
                Authorization: `Bearer ${authTokens?.accessToken}`
            }
        }).then(({ data }) => {
            setShippingPrices(data.shippingPrices);
            setNumberOfPages(data.totalPages);
        }).catch((error) => {
            console.log(error);
        })
    }, [page, shippingPriceSearch])

    const visiblePages = 10; // Number of visible page numbers

    const firstPage = Math.max(1, page - Math.floor(visiblePages / 2));
    const lastPage = Math.min(numberOfPages, firstPage + visiblePages - 1);

    const pageNumbers = Array.from({ length: lastPage - firstPage + 1 }, (_, i) => firstPage + i);



    return (
        <div className="mt-12">


            <div className="flex justify-between flex-row items-center pb-4">
                <div></div>

                <div className='flex items-center'>
                    <Button size="sm" className="bg-primary capitalize text-base font-normal text-white flex items-center shadow-none rounded-none"
                        onClick={() => handleOpenAddShippingPrice()}
                    >
                        <PlusIcon className="w-4 h-4 mr-2" />
                        Ajouter un prix de livraison
                    </Button>
                </div>
            </div>

            <Card shadow={false} className="h-full w-full bg-secondary border border-gray-400 rounded-none">
                <CardBody className={`px-0 xl:overflow-hidden`} /* overflow-scroll  */>

                    <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center p-4 text-primary">
                        <div>
                            <Typography variant="h4">
                                Prix de livraison récents
                            </Typography>
                            <Typography className="mt-1">
                                Ce sont des détails sur les derniers prix de livraison
                            </Typography>
                        </div>
                        <div className="flex w-full shrink-0 gap-2 md:w-max">

                            <div className="sm:flex-1 sm:mr-6 sm:mb-0 mb-3">
                                <Input className='rounded-none bg-white' label="Recherche par pays" type="text" onChange={(e) => setShippingPriceSearch(e.target.value)} />
                            </div>

                            {/* <div className='sm:flex-1 sm:mr-6 sm:mb-0 mb-3'><DatePicker date={date} setDate={setDate} /></div> */}
                        </div>
                    </div>

                    {shippingPrices.length !== 0 ?
                        <div className={`${shippingPrices.length !== 0 && 'overflow-x-scroll'}`}>
                            <table className="w-full min-w-max table-auto text-left">
                                <thead>
                                    <tr>
                                        {TABLE_HEAD.map((head) => (
                                            <th
                                                key={head}
                                                className="border-y border-white bg-primary p-4"
                                            >
                                                <Typography
                                                    variant="paragraph"
                                                    color="white"
                                                    className=" leading-none"
                                                >
                                                    {head}
                                                </Typography>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {shippingPrices.map(
                                        (
                                            {
                                                id,
                                                zone,
                                                country,
                                                price,

                                                // city,
                                                // productId, 
                                                // product
                                            },
                                            index,
                                        ) => {
                                            const isLast = index === shippingPrices.length - 1;
                                            const classes = isLast
                                                ? "p-4"
                                                : "p-4 border-b border-blue-gray-50";

                                            return (
                                                <tr key={id} className="text-primary">
                                                    <td className={classes}>
                                                        <div className="flex items-center gap-3">
                                                            <Typography
                                                                variant="paragraph"
                                                                color="blue-gray"
                                                                className="capitalize text-primary"
                                                            >
                                                                {zone}
                                                            </Typography>
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <Typography
                                                            variant="paragraph"
                                                            color="blue-gray"
                                                            className="capitalize text-primary"
                                                        >
                                                            {country}
                                                        </Typography>
                                                    </td>

                                                    {/* <td className={classes}>
                                                        <Typography
                                                            variant="paragraph"
                                                            color="blue-gray"
                                                            className="capitalize text-primary"
                                                        >
                                                            {city}
                                                        </Typography>
                                                    </td> */}

                                                    <td className={classes}>
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="capitalize text-primary"
                                                        >
                                                            {price} AED
                                                        </Typography>
                                                    </td>
                                                    {/* <td className={classes}>
                                                        {productId ?
                                                            <Typography
                                                                variant="small"
                                                                color="blue-gray"
                                                                className="capitalize text-primary"
                                                            >
                                                                {product?.name}
                                                            </Typography>
                                                            : null
                                                        }
                                                    </td> */}
                                                    <td className={classes}>
                                                    <Tooltip content="Supprimer">
                                                            <IconButton variant="text" onClick={() => { setSelectedShippingPrice({ id, zone, country, price }); handleOpenAddShippingPrice() }}>
                                                                <BsPencil className="h-4 w-4 text-primary" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip content="Supprimer">
                                                            <IconButton variant="text" onClick={() => { setSelectedShippingPrice({ id, country }); handleOpenDeleteShippingPrice() }}>
                                                                <BsTrash className="h-4 w-4 text-primary" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            );
                                        },
                                    )}
                                </tbody>
                            </table>
                        </div> :
                        (
                            <div className="w-full">
                                <div className="flex flex-col justify-center items-center bg-primary text-secondary p-6 rounded-none mx-6">
                                    <ExclamationTriangleIcon className='w-20 mb-4' />
                                    <Typography variant="lead" className="">
                                        {(shippingPriceSearch) ? "Vous n'avez pas un prix prix de livraison avec ce filtre" : "Vous n'avez encore pas de prix !"}
                                    </Typography>
                                </div>
                            </div>
                        )}
                </CardBody>
                {shippingPrices.length !== 0 && <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                    <Button disabled={page === 1} onClick={() => setPage(page - 1)} variant="outlined" size="sm" className="bg-white text-primary capitalize rounded-none">
                        Précédent
                    </Button>
                    <div className="flex items-center gap-2">
                        {pageNumbers.map((pageNum) => (
                            <IconButton onClick={() => setPage(pageNum)} key={pageNum} variant={page === pageNum ? "outlined" : "text"} size="sm" className={`${page === pageNum && "border-primary"} text-primary rounded-none`}>
                                {pageNum}
                            </IconButton>
                        ))}
                    </div>
                    <Button disabled={page === numberOfPages} onClick={() => setPage(page + 1)} variant="filled" size="sm" className="bg-white text-primary capitalize rounded-none shadow-none">
                        Suivant
                    </Button>
                </CardFooter>}
            </Card>

            <AddShippingPrice open={openAddShippingPrice} handleOpen={handleOpenAddShippingPrice} setShippingPrices={setShippingPrices} selectedShippingPrice={selectedShippingPrice} setSelectedShippingPrice={setSelectedShippingPrice} />
            <DeleteShippingPrice open={openDeleteShippingPrice} handleOpen={handleOpenDeleteShippingPrice} setShippingPrices={setShippingPrices} selectedShippingPrice={selectedShippingPrice} setSelectedShippingPrice={setSelectedShippingPrice} />
        </div>
    )
}

export default ShippingPrices