// import React, { forwardRef, useContext, useEffect, useState } from 'react';
// import { AuthContext } from '../../../contexts/AuthContext';
// import {
//     Button,
//     Dialog,
//     Card,
//     CardBody,
//     CardFooter,
//     Typography,
//     Input,
//     Spinner,
//     Select,
//     Option,
//     Switch,
// } from "@material-tailwind/react";
// import axios from 'axios';
// import { API_LINK } from '../../../apiConfig';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { registerLocale, setDefaultLocale } from "react-datepicker";
// import { format } from 'date-fns';
// import fr from 'date-fns/locale/fr';

// const AddShippingPrice = ({ open, handleOpen, setShippingPrices, selectedShippingPrice, setSelectedShippingPrice }) => {
//     const { authTokens } = useContext(AuthContext);

//     registerLocale('fr', fr);

//     const [loading, setLoading] = useState(false);

//     const [products, setProducts] = useState([]);

//     // Initialize state variables to an empty string or null
//     const [zone, setZone] = useState('');
//     const [country, setCountry] = useState('');
//     const [price, setPrice] = useState('');


//     // const [city, setCity] = useState('');
//     // const [selectedProduct, setSelectedProduct] = useState(null);
//     // const [forAll, setForAll] = useState(true);


//     useEffect(() => {
//         if (open) {
//             if (selectedShippingPrice) {
//                 setZone(selectedShippingPrice?.zone || '');
//                 setCountry(selectedShippingPrice?.country || '');
//                 setPrice(selectedShippingPrice?.price || '');
//             }
//         }
//     }, [open, selectedShippingPrice]);




//     useEffect(() => {
//         axios.get(`${API_LINK}/products/search`).then(({ data }) => {
//             setProducts(data);
//         }).catch((error) => {
//             console.log(error);
//         })
//     }, [])


//     const AddShippingPriceHandler = () => {
//         setLoading(true);
//         if (zone && country && price) {
//             const requestConfig = {
//                 headers: {
//                     Authorization: `Bearer ${authTokens?.accessToken}`
//                 }
//             };

//             axios.post(`${API_LINK}/shippingprices/`, {
//                 zone,
//                 country,
//                 price: parseFloat(price),
//                 // city,
//                 // productId: (selectedProduct && !forAll) ? parseInt(selectedProduct) : null
//                 // city: parseInt(city),
//                 // forAll
//             }, requestConfig).then(({ data }) => {
//                 setShippingPrices(shippingprices => [data, ...shippingprices]);
//                 closeModalHandler();
//             }).catch((error) => {
//                 console.log(error);
//             }).finally(() => {
//                 setLoading(false);
//             });
//         } else {
//             alert("Veuillez remplir tous les champs");
//         }
//     };

//     const closeModalHandler = () => {
//         handleOpen();
//         setZone('');
//         setCountry('');
//         setPrice('');
//         setSelectedShippingPrice(null);
//     };

//     return (
//         <Dialog
//             size="xs"
//             open={open}
//             handler={closeModalHandler}
//             className="bg-transparent shadow-none"
//         >
//             {!loading ? (
//                 <Card className="mx-auto w-full">
//                     <CardBody className="flex flex-col gap-4">
//                         <div className='flex justify-between items-center '>
//                             <Typography variant="h4" color="blue-gray">
//                                 Ajouter Un prix de livraison
//                             </Typography>
//                             <div className="mx-5">
//                                 {/* <Switch color="green" label='Tous les produits' checked={forAll} onChange={(e) => setForAll(e.target.checked)} /> */}
//                             </div>
//                         </div>
//                         <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 w-full">

//                             <div className='sm:col-span-2 col-span-1'>
//                                 <Select
//                                     label="Zone"
//                                     value={zone}
//                                     onChange={(e) => setZone(e)}
//                                 >
//                                     <Option value="middle-east">Middle East</Option>
//                                     <Option value="europe">Europe</Option>
//                                 </Select>
//                             </div>

//                             <Input
//                                 label="Pays"
//                                 type="text"
//                                 value={country}
//                                 onChange={(e) => setCountry(e.target.value)}
//                             />

//                             {/* <Input
//                                 label="Ville"
//                                 type="text"
//                                 value={city}
//                                 onChange={(e) => setCity(e.target.value)}
//                             /> */}
//                             <Input
//                                 label="Prix"
//                                 type="text"
//                                 value={price}
//                                 onChange={(e) => setPrice(e.target.value)}
//                             />


//                             {/* <div className='sm:col-span-2 col-span-1'>
//                                 {forAll === false && <Select
//                                     label="Sélectionner produit"
//                                     value={selectedProduct}
//                                     onChange={(e) => setSelectedProduct(e)}
//                                 >
//                                     {products.map((product) => <Option key={product.id} value={product.id}>{product.name}</Option>)}
//                                 </Select>}
//                             </div> */}

//                         </div>
//                     </CardBody>
//                     <CardFooter className="pt-0">
//                         <Button
//                             variant="filled"
//                             className="bg-primary rounded-none shadow-none"
//                             onClick={AddShippingPriceHandler}
//                             fullWidth
//                         >
//                             Ajouter
//                         </Button>
//                     </CardFooter>
//                 </Card>
//             ) : (
//                 <div className="flex justify-center items-center p-5">
//                     <Spinner />
//                 </div>
//             )}
//         </Dialog>
//     );
// };

// export default AddShippingPrice;


import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../contexts/AuthContext';
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Input,
    Spinner,
    Select,
    Option,
    Switch,
} from "@material-tailwind/react";
import axios from 'axios';
import { API_LINK } from '../../../apiConfig';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

const AddShippingPrice = ({ open, handleOpen, setShippingPrices, selectedShippingPrice, setSelectedShippingPrice }) => {
   
    const { authTokens } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [zone, setZone] = useState('');
    const [country, setCountry] = useState('');
    const [price, setPrice] = useState('');

    // Initialize the component with selectedShippingPrice values if in edit mode
    useEffect(() => {
        if (open && selectedShippingPrice) {
            setZone(selectedShippingPrice?.zone || '');
            setCountry(selectedShippingPrice?.country || '');
            setPrice(selectedShippingPrice?.price);
        }
    }, [open, selectedShippingPrice]);

    useEffect(() => {
        axios.get(`${API_LINK}/products/search`).then(({ data }) => {
            setProducts(data);
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    const handleAddOrUpdate = () => {
        setLoading(true);
        if (zone && country && price && parseInt(price) >= 0 ) {
            const requestConfig = {
                headers: {
                    Authorization: `Bearer ${authTokens?.accessToken}`
                }
            };

            const payload = {
                zone,
                country,
                price: parseFloat(price),
            };

            const requestUrl = selectedShippingPrice
                ? `${API_LINK}/shippingprices/${selectedShippingPrice.id}`  // Update
                : `${API_LINK}/shippingprices/`;  // Add new

            const requestMethod = selectedShippingPrice ? 'patch' : 'post';  // PUT for update, POST for add

            axios[requestMethod](requestUrl, payload, requestConfig)
                .then(({ data }) => {
                    if (selectedShippingPrice) {
                        // Update the specific shipping price in the state
                        setShippingPrices((prevPrices) => 
                            prevPrices.map(price => 
                                price.id === data.id ? data : price
                            )
                        );
                    } else {
                        // Add the new shipping price to the state
                        setShippingPrices((prevPrices) => [data, ...prevPrices]);
                    }
                    closeModalHandler();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            alert("Veuillez remplir tous les champs");
        }
    };

    const closeModalHandler = () => {
        handleOpen();
        setZone('');
        setCountry('');
        setPrice('');
        setSelectedShippingPrice(null);
    };

    return (
        <Dialog
            size="xs"
            open={open}
            handler={closeModalHandler}
            className="bg-transparent shadow-none"
        >
            {!loading ? (
                <Card className="mx-auto w-full">
                    <CardBody className="flex flex-col gap-4">
                        <div className='flex justify-between items-center '>
                            <Typography variant="h4" color="blue-gray">
                                {selectedShippingPrice ? "Modifier" : "Ajouter"} Un prix de livraison
                            </Typography>
                        </div>
                        <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 w-full">
                            <div className='sm:col-span-2 col-span-1'>
                                <Select
                                    label="Zone"
                                    value={zone}
                                    onChange={(e) => setZone(e)}
                                >
                                    <Option value="middle-east">Middle East</Option>
                                    <Option value="europe">Europe</Option>
                                </Select>
                            </div>

                            <Input
                                label="Pays"
                                type="text"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                            />
                            <Input
                                label="Prix"
                                type="text"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </div>
                    </CardBody>
                    <CardFooter className="pt-0">
                        <Button
                            variant="filled"
                            className="bg-primary rounded-none shadow-none"
                            onClick={handleAddOrUpdate}
                            fullWidth
                        >
                            {selectedShippingPrice ? "Modifier" : "Ajouter"}
                        </Button>
                    </CardFooter>
                </Card>
            ) : (
                <div className="flex justify-center items-center p-5">
                    <Spinner />
                </div>
            )}
        </Dialog>
    );
};

export default AddShippingPrice;
